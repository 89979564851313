<template>
  <div class="app-form">
    <el-row class="list-content" v-if="!isShowListError">
      <div v-if="listStyle === 'list'">
        <el-row v-for="(item,index) in list" :key="index" :gutter="0" class="el-row" type="flex" style="margin: 0; padding: 20px 0px; border-bottom: 2px solid #F8F9FC; box-sizing: border-box;">
          <el-col :span="4" :xs="24" style="padding: 0; width: 200px; height: 120px;">
            <span class="form-img" @click="onDetailClick(item)">
              <img :src="item.cover ? item.cover + '?x-oss-process=image/resize,m_fixed,h_300,w_500' : item.type === 'project' ? projectDefaultHeadImg + '?x-oss-process=image/resize,m_fixed,h_300,w_500' : formDefaultHeadImg + '?x-oss-process=image/resize,m_fixed,h_300,w_500'">
            </span>
          </el-col>
          <el-col :span="20" :xs="24" style="padding-left: 0; padding-right: 0; margin-left: 20px; cursor: pointer">
            <div class="form-info" @mouseenter="onRowMouseEnter(item)" @mouseleave="onRowMouseLeave(item)">
              <div class="form-title">
                <span class="title-span" @click="onDetailClick(item)">{{item.name}}</span>
                <el-tag v-if="!item.isStop" size="mini" class="form-tag stop" type="info">已暂停</el-tag>
                <el-tag v-else-if="item.type !== 'project' && item.formStateTxt" size="mini" class="form-tag" :class="getTagClass(item.formStateTxt)">{{item.formStateTxt}}</el-tag>
                <div class="fr form-item-share">
                  <div class="form-collect" @click="onCollectClick(item)">
                    <el-button size="mini" class="collect-btn" :class="{'active':item.favorite}">
                      <svg-icon class="collect-icon" :icon-class="item.favorite ? 'formListCollect' : 'formListUnCollect'" />
                      <span>{{item.favorite ? '已收藏' : '收藏'}}</span>
                    </el-button>
                  </div>
                  <div class="form-share" @click="onNoShareClick" v-if="item.status == '1'">
                    <el-button size="mini" disabled>
                      <svg-icon class="share-icon" icon-class="formListShare" />
                      <span>分享</span>
                    </el-button>
                  </div>
                  <div class="form-share" @click="onShareClick(item)" v-else>
                    <el-button size="mini">
                      <svg-icon class="share-icon" icon-class="formListShare" />
                      <span>分享</span>
                    </el-button>
                  </div>
                </div>
                <div class="form-share" v-if="cur === 1 && item.userId === loginUserId">
                  <el-button size="mini" @click="showSetHomeDialog(item)" v-if="item.agencyHome==0">
                    <svg-icon class="share-icon" icon-class="home-o" />
                    <span>置于机构首页</span>
                  </el-button>
                  <el-button size="mini" @click="onCancelHomeClick(item)" v-if="item.agencyHome==1">
                    <svg-icon class="share-icon" icon-class="home-o" />
                    <span>取消置于机构首页</span>
                  </el-button>
                </div>
                <div class="form-share" v-else-if="curRoutePath === '/index' && item.userId === loginUserId">
                  <el-button size="mini" @click="onCancelHomeClick(item)">
                    <svg-icon class="share-icon" icon-class="home-o" />
                    <span>取消置于机构首页</span>
                  </el-button>
                </div>
              </div>
              <div class="form-list-bottom" :style="{bottom: cur == 1 ?  (item.type == 'form' ? '-6px' : '-10px') : '0px'}">
                <div class="form-date" @click="onDetailClick(item)" v-if="cur == 1" :style="[{lineHeight: cur == 1 ?  (item.type == 'form' ? '22px' : '30px') : ''}, {height: cur == 1 ?  (item.type == 'form' ? '22px' : '30px') : ''}]">
                  <div class="fill-date">
                    <span class="fill-date-label">创建时间</span>
                    <span style="margin: 0 10px">:</span>
                    <span>{{item.createTime}}</span>
                  </div>
                </div>
                <div class="form-date" v-if="item.type == 'form'" @click="onDetailClick(item)" :style="[{lineHeight: cur == 1 ?  (item.type == 'form' ? '22px' : '30px') : '30px'}, {height: cur == 1 ?  (item.type == 'form' ? '22px' : '30px') : '30px'}]">
                  <template v-if="item.fillStartTime || item.fillEndTime || (item.fillStartTime && item.fillEndTime)">
                    <p class="fill-date">
                      <span class="fill-date-label">填报时间</span>
                      <span style="margin: 0 10px">:</span>
                      <span>{{item.fillStartTime}}</span>
                      <span style="margin: 0 5px" v-show="item.fillStartTime && item.fillEndTime"> - </span>
                      <span>{{item.fillEndTime}}</span>
                    </p>
                  </template>
                  <template v-if="!item.fillStartTime && !item.fillEndTime">
                    <div class="fill-date">
                      <span class="fill-date-label">填报时间</span>
                      <span style="margin: 0 10px">:</span>
                      <span>——</span>
                    </div>
                  </template>
                </div>
                <div class="form-date" @click="onDetailClick(item)" :style="[{lineHeight: cur == 1 ?  (item.type == 'form' ? '22px' : '30px') : '30px'}, {height: cur == 1 ?  (item.type == 'form' ? '22px' : '30px') : '30px'}]">
                  <div class="fill-date">
                    <span class="fill-date-label">创建人</span>
                    <span style="margin: 0 10px">:</span>
                    <span>{{item.founder ? item.founder : item.createName}}</span>
                    <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png" class="is-agency-icon" v-if="item.agencyId" />
                    <span v-else>（个人）</span>
                    <div style="float:right;display:inline-block;">
                      <formTagEditor :readonly="true" :value="getFormTags(item)" :tagList="tagList"></formTagEditor>
                    </div>
                  </div>
                </div>
                <div class="form-date" :style="[{lineHeight: cur == 1 ?  (item.type == 'form' ? '22px' : '30px') : '30px'}, {height: cur == 1 ?  (item.type == 'form' ? '22px' : '30px') : '30px'}]">
                  <p class="fill-date fl" @click="onDetailClick(item)">
                    <span class="fill-date-label">类型</span>
                    <span style="margin: 0 10px">:</span>
                    <svg-icon v-if="item.type === 'project'" icon-class="formListProject" class="form-type-icon" />
                    <svg-icon v-else icon-class="formListForm" class="form-type-icon" />
                    <span>{{item.type == 'project' ? '应用' : '表单'}}</span>
                  </p>
                  <div class="form-create fr" v-show="item.showBtn" :style="[{lineHeight: cur == 1 ?  (item.type == 'form' ? '22px' : '30px') : '30px'}, {height: cur == 1 ?  (item.type == 'form' ? '22px' : '30px') : '30px'}]">
                    <div class="fr form-settings" vif="item.userId == loginUserId">
                      <!-- <span @click="onEditProjectClick(item.projectId)" v-if="item.type == 'project'"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-edit-icon.png"/>修改</span>
                      <span @click="onCopyProjectClick(item.projectId)" v-if="item.type == 'project'"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-copy-icon.png"/>复制</span>
                      <span @click="onDeleteProjectClick(item.projectId)" v-if="item.type == 'project'"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-dismiss-icon.png"/>删除</span>
                      <span @click="onEditFormClick(item)" v-if="item.type == 'form'"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-edit-icon.png"/>修改</span>
                      <span @click="onCopyFormClick(item.formId)" v-if="item.type == 'form'"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-copy-icon.png"/>复制</span>
                      <span @click="onMoveFormClick(item.formId)" v-if="item.type == 'form'"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-move-icon.png"/>移动</span>
                      <span @click="onDeleteFormClick(item.formId)" v-if="item.type == 'form'"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-dismiss-icon.png"/>删除</span>
                      <span @click="onPauseFormClick(item.isStop)" v-if="!item.isStop"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-pause-icon.png" />暂停</span>
                      <span @click="onStartFormClick(item.isStop)" v-if="!!item.isStop"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-start-icon.png" />开始</span> -->
                      <editLinks :formDetail="item" :loginUserId="loginUserId" :loginAgencyUserId="loginAgencyUserId" :formType="item.type" returnType="refresh" @refreshList="refreshList" @formStopUpdate="handleFormStopUpdate" @editProject="onEditProjectClick"></editLinks>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <!-- <el-col :span="1" :xs="1" style="cursor: pointer; padding-left: 0; padding-right: 0; text-align: center; height: 110px; line-height: 110px; position: absolute; right: -12px;" v-if="item.type == 'project' && item.formTotalNum > 0 && !item.isSubShow">
            <div style="width: 100%; text-align: center; margin: auto; display: inline-block; height: 110px; cursor: pointer" @click="onShowToggle(item, index)">
              <span class="arrow-right"></span>
            </div>
          </el-col>
          <el-col :span="1" :xs="1" style="cursor: pointer; padding-left: 0; padding-right: 0; text-align: center; height: 19px; line-height: 19px; position: absolute; bottom: 7px; text-align: center;width: 100%;" v-else-if="item.type == 'project' && item.formTotalNum > 0 && item.isSubShow">
          <div style="width: 100%; text-align: center; margin: auto; display: inline-block; height: 19px; cursor: pointer" @click="onShowToggle(item, index)">
            <span class="arrow-bottom"></span>
          </div>
          </el-col> -->
        </el-row>
      </div>
      <div v-if="listStyle === 'card'">
        <el-row :gutter="16">
          <el-col v-for="(item,index) in list" :key="index" :xs="12" :sm="12" :md="8" :lg="6" :xl="6" style="margin-top: 10px;">
            <div class="card-box">
              <div class="imgBx" @click="onDetailClick(item)"><img :src="item.cover ? item.cover + '?x-oss-process=image/resize,m_fixed,h_300,w_500' : item.type === 'project' ? projectDefaultHeadImg + '?x-oss-process=image/resize,m_fixed,h_300,w_500' : formDefaultHeadImg + '?x-oss-process=image/resize,m_fixed,h_300,w_500' " /></div>
              <div class="content">
                <div>
                  <div style="float: left;">
                    <el-tooltip class="item" effect="dark" :content="item.name" placement="top" popper-class="tips">
                      <h3 @click="onDetailClick(item)">
                        <svg-icon v-if="item.type === 'project'" icon-class="formListProject" class="form-type-icon" style="margin: 0;" />
                        <svg-icon v-else icon-class="formListForm" class="form-type-icon" style="margin: 0;" />
                        {{item.name.length >=9?item.name.slice(0,9)+'...':item.name}}
                      </h3>
                    </el-tooltip>
                  </div>
                  <div class="icon" style="float: right;">
                    <el-tooltip content="分享" placement="top">
                      <i v-if="item.status == '1'" class="el-icon-share card-box-icon-hide" @click="onNoShareClick" ></i>
                      <i v-else class="el-icon-share card-box-icon-hide" @click="onShareClick(item)" ></i>
                    </el-tooltip>
                    <span v-if="item.userId === loginUserId">
                      <el-tooltip :content="item.agencyHome==0 ? '置于机构首页' : '取消置于机构首页'" placement="top">
                        <i class="el-icon-s-flag card-box-icon-hide" v-if="cur === 1 && item.agencyHome==0" @click="showSetHomeDialog(item)"></i>
                        <i class="el-icon-s-flag card-box-icon-show" style="color: #f7ba2a;" v-if="item.agencyHome==1 || curRoutePath === '/index'" @click="onCancelHomeClick(item)"></i>
                      </el-tooltip>
                    </span>
                    <el-tooltip :content="item.favorite ? '取消收藏': '收藏'" placement="top">
                      <i @click="onCollectClick(item)" :class="item.favorite ? 'el-icon-star-on card-box-icon-show' : 'el-icon-star-off card-box-icon-hide'" :style="{color: item.favorite ? '#f7ba2a' : '', fontSize: item.favorite ? '19px': ''}"></i>
                    </el-tooltip>
                  </div>
                </div>
                <p>
                  <editLinks class="card-edit-links" :formDetail="item" :loginUserId="loginUserId" :loginAgencyUserId="loginAgencyUserId" :formType="item.type" returnType="refresh" @refreshList="refreshList" @formStopUpdate="handleFormStopUpdate" @editProject="onEditProjectClick"></editLinks>
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-row>
    <template v-else>
      <div class="list-no-data" v-if="cur == 1">
        <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list_create_no.png" class="no-data-img">
        <span class="no-data-text">快去创建你的应用或者表单吧~</span>
      </div>
      <div class="list-no-data" v-if="cur == 2">
        <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list_fill_no.png" class="no-data-img">
        <span class="no-data-text">你还没有填报数据哦~</span>
      </div>
      <div class="list-no-data" v-if="cur == 3">
        <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list_review_no.png" class="no-data-img">
        <span class="no-data-text">你还没有审核数据哦~</span>
      </div>
      <div class="list-no-data" v-if="cur == 4">
        <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list_manage_no.png" class="no-data-img">
        <span class="no-data-text">你还没有管理数据哦~</span>
      </div>
    </template>
    <el-dialog title="填报地址" :visible.sync="shareOpen" width="1000px" append-to-body @close="handleFillDiaClose">
      <div class="share-container">
        <h5 style="font-size: 20px; color: #404040;  margin: 20px 0 10px;">链接分享</h5>
        <p style="font-size: 12px; color: #909090; margin-bottom: 10px;">将填报地址粘贴到浏览器访问，或者直接发给填报者</p>
        <div class="" style="width: 100%;height: auto; ">
          <el-input type="text" :value="shareFillUrl" readonly class="fl" style="width: 60%; margin-right: 40px"></el-input>
          <p>
            <el-button type="primary" @click="onCopyFillClick">复制链接</el-button>
            <el-button type="primary" style="margin-left: 20px;" @click="onToClick">直接打开</el-button>
          </p>
        </div>
        <h5 style="font-size: 20px; color: #404040;  margin: 20px 0 10px;">小程序码分享</h5>
        <p style="font-size: 12px; color: #909090; margin-bottom: 10px;">选择小程序码样式，保存小程序码直接发给填报者</p>
        <img :src="shareImageUrl" class="list-share-image">
        <div style=" width: 398px; margin: auto; text-align: center;">
          <el-button type="primary" @click="onSaveClick" style="margin: 20px auto 0;">保存小程序码</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="移动表单" :visible.sync="formMoveVisible" width="600px" @close="onCloseMoveClick">
      <el-form ref="formMove" :model="formMoveObj" :rules="formMoveRules" label-width="100px" label-position="right" @submit.native.prevent>
        <el-form-item label="所移动应用" prop="projectId">
          <el-col :span="24">
            <el-select v-model="formMoveObj.projectId" placeholder="请选择移动应用" filterable style="width: 100%">
              <el-option v-for="item in moveFormProjectList" :key="item.projectId" :label="item.name" :value="item.projectId" />
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmMoveClick">确 定</el-button>
        <el-button @click="onCancelMoveClick">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 弹出，包含一个el-select选项，选项为:1：所有机构成员可见 2：部分机构成员可见  -->
    <el-dialog title="请选择置顶可见性" :visible.sync="formAgencyVisible" width="600px" @close="onCancelAgencyClick">
      <el-form ref="formAgency" :model="formAgencyObj" :rules="formAgencyRules" label-width="100px" label-position="right" @submit.native.prevent>
        <el-form-item label="置顶可见性" prop="agencyVisible">
          <el-col :span="24">
            <el-select v-model="formAgencyObj.agencyVisible" placeholder="请选择置顶可见性" filterable style="width: 100%">
              <el-option v-for="item in agencyVisibleList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-col>
          <!-- 如果选择的值是部分机构成员可见，引入memberSelect 选择机构成员 -->
          <div v-if="formAgencyObj.agencyVisible == 2" style="margin-top:50px;">
            <ul v-if="formAgencyObj.memberList.length > 0 " class="select-list" @click="showMemberSelectDialog">
              <li v-for="(item,index) in formAgencyObj.memberList" :key="index" class="select-item">
                <template v-if="item.type == 'member'">
                  <svg-icon icon-class="memberIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
                </template>
                <template v-if="item.type == 'dept'">
                  <svg-icon icon-class="deptIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
                </template>
                <template v-if="item.type == 'role'">
                  <svg-icon icon-class="roleIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
                </template>
                <span>{{item.name}}</span>
              </li>
            </ul>
            <div class="designated-member" @click="showMemberSelectDialog" v-else>
              <span class="member-empty">
                <svg-icon icon-class="add" style="width: 20px; height: 20px; margin-right: 5px; color: #5e6d82; vertical-align: -5px;" />点击添加指定成员
              </span>
            </div>
          </div>
        </el-form-item>
        <member-select ref="memberSelect" :agencyId="setHomeItem.agencyId" :showType="[1]"></member-select>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmAgencyClick">确 定</el-button>
        <el-button @click="onCancelAgencyClick">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    stopOrStart,
    copyProject,
    dismissProject,
    projectSelectList,
    moveForm,
    copyForm,
    deleteProject,
    deleteForm,
    httpShareNum,
    shareImg,
    getTagListByUserId,
    shortLink,
    setFormAgencyHome,
  } from "@/api/system/form";
  import { getSessionStorageObj } from "@/utils/db";
  import { setOssConfig } from "@/utils/ossUtils";
  import { favorite } from "@/api/system/user";
  import formTagEditor from "@/views/system/form/components/formTag/editor.vue";
  import editLinks from "@/views/system/form/components/editLinks.vue";
  import memberSelect from "@/views/system/form/components/setAuditMemberSelect.vue";
  export default {
    name: "fillList",
    props: {
      list: {
        type: Array,
      },
      cur: {
        type: String|Number,
        default: 1,
      },
      listStyle: {
        type: String,
        default: "card",
      },
      isShowListError: {
        type: Boolean,
      },
      tagList: {
        type: Array,
        default: () => {
          return null;
        },
      },
    },
    components: { formTagEditor, editLinks, memberSelect },
    data() {
      return {
        loginUserId: null,
        loginAgencyUserId: null,
        // defaultHeadImg: 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form_default.jpeg',
        projectDefaultHeadImg: require("@/assets/images/project-default-img.png"),
        formDefaultHeadImg: require("@/assets/images/form-default-img.png"),
        shareOpen: false,
        shareFillUrl: "",
        shareItemInfo: {}, // 点击分享每个表单信息
        base64DataUrl: "", // 保存小程序码，便于下载
        shareImageUrl: "", // 新版---分享图片 base64
        formMoveVisible: false,
        formMoveObj: {
          projectId: null,
        },
        formMoveRules: {
          projectId: [
            {
              required: true,
              message: "所移动应用不能为空",
              trigger: ["change"],
            },
          ],
        },
        moveFormId: null,
        moveFormProjectList: [], // 表单移动所选择的应用列表
        formAgencyVisible: false,
        formAgencyObj: {
          agencyVisible: null,
          memberList: [],
        },
        formAgencyRules: {
          agencyVisible: [
            {
              required: true,
              message: "机构可见性不能为空",
              trigger: ["change"],
            },
          ],
        },
        agencyVisibleList: [
          {
            label: "仅机构成员可见",
            value: 1,
          },
          // {
          //   label: '部分机构成员可见',
          //   value: 2
          // }
        ],
        setHomeItem: {
          agencyId: null,
        },
      };
    },
    computed: {
      // 获取当前路由Path
      curRoutePath() {
        return this.$route.path;
      },
    },
    created() {
      this.loginUserId = getSessionStorageObj("users").userId;
      this.loginAgencyUserId = (
        getSessionStorageObj("users").agencyUserId || ""
      ).split(",");
      console.log("this.loginAgencyUserId", this.loginAgencyUserId);
    },
    methods: {
      getTagClass(text) {
        switch (text) {
          case "进行中":
            return "ing";
          case "已结束":
            return "stop";
          default:
            return "un-start";
        }
      },
      // 跳转详情
      onDetailClick(item, index) {
        if (item.type == "project") {
          // 应用
          this.$router.push({
            path: "/form/multipleFormDetail/" + this.encode(item.projectId),
          });
        } else if (item.type == "form") {
          if (item.projectId && item.projectId != "0") {
            // projectId != 0 代表是应用中的表单，反之是单个表单
            this.$router.push({
              path:
                "/form/multipleFormDetail/" +
                this.encode(item.projectId) +
                "?formId=" +
                this.encode(item.formId),
            });
          } else {
            this.$router.push({
              path: "/form/detail/" + this.encode(item.formId),
            });
          }
        }
      },
      // 修改应用
      onEditProjectClick(id) {
        setOssConfig();
        this.$emit("editProject", id);
      },
      refreshList() {
        this.$emit("get-refresh-list");
      },
      // // 复制应用
      // onCopyProjectClick(id) {
      //   let _this = this
      //   _this.$confirm('确认复制该应用？', "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning"
      //   }).then(function() {
      //     return copyProject(id)
      //   }).then(() => {
      //     _this.$message({
      //       type: 'success',
      //       message: '复制成功'
      //     })
      //     _this.$emit('get-refresh-list')
      //   }).catch(function() {})
      // },
      // // 解散应用
      // onDismissProjectClick(id) {
      //   let _this = this
      //   _this.$confirm('确认删除该应用？', "警告", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning"
      //   }).then(function() {
      //     return dismissProject(id)
      //   }).then(() => {
      //     _this.$message({
      //       message: '删除应用成功',
      //       type: 'success'
      //     })
      //     _this.$emit('get-refresh-list')
      //   }).catch(function() {})
      // },
      // // 删除应用
      // onDeleteProjectClick(id) {
      //   let _this = this
      //   _this.$confirm('确认删除该应用？', "警告", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning"
      //   }).then(function() {
      //     return deleteProject(id)
      //   }).then(() => {
      //     _this.$message({
      //       message: '删除应用成功',
      //       type: 'success'
      //     })
      //     _this.$emit('get-refresh-list')
      //   }).catch(function() {})
      // },
      // 移动表单
      // onMoveFormClick(id) {
      //   this.clearMoveFormData()
      //   this.moveFormId = id
      //   this.formMoveVisible = true
      //   this.getMoveProject()
      // },
      getMoveProject() {
        let _this = this;
        projectSelectList().then((response) => {
          if (response.code == 200) {
            _this.moveFormProjectList = response.data;
          }
        });
      },
      onConfirmMoveClick() {
        let _this = this;
        this.$refs["formMove"].validate((valid) => {
          if (valid) {
            let param = {
              formId: _this.moveFormId,
              projectId: _this.formMoveObj.projectId,
            };
            moveForm(param).then((response) => {
              if (response.code == 200) {
                _this.msgSuccess("移动成功");
                _this.$emit("get-refresh-list");
                _this.formMoveVisible = false;
                _this.clearMoveFormData();
              }
            });
          }
        });
      },
      onCancelMoveClick() {
        this.formMoveVisible = false;
        this.clearMoveFormData();
      },
      onCloseMoveClick() {
        this.clearMoveFormData();
      },
      clearMoveFormData() {
        if (this.$refs["formMove"]) {
          this.$refs["formMove"].clearValidate();
        }
        this.moveFormId = null;
        this.formMoveObj = {
          projectId: null,
        };
        this.moveFormProjectList = [];
      },
      // 切换应用 显示表单
      onShowToggle(item, ind) {
        let _this = this;
        _this.list.forEach((i) => {
          // 判断如果数据中的menuList[i]的show属性不等于当前数据的isSubShow属性那么menuList[i]等于false
          if (i.isSubShow !== _this.list[ind].isSubShow) {
            i.isSubShow = false;
          }
        });
        item.isSubShow = !item.isSubShow;
      },
      // 复制表单
      onCopyFormClick(id) {
        let _this = this;
        _this
          .$confirm("确认复制该表单？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(function () {
            return copyForm(id);
          })
          .then(() => {
            _this.$message({
              type: "success",
              message: "复制成功",
            });
            _this.$emit("get-refresh-list");
          })
          .catch(function () {});
      },
      // 编辑修改表单
      onEditFormClick(item) {
        let type = item.type;
        if (type == "form") {
          if (item.projectId != 0) {
            this.$router.push({
              path: "/form/edit/" + this.encode(item.formId),
              query: {
                name: this.encode(item.name),
                projectId: this.encode(item.projectId),
              },
            });
          } else {
            this.$router.push({
              path: "/form/edit/" + this.encode(item.formId),
              query: {
                name: this.encode(item.name),
                projectId: 0,
              },
            });
          }
        }
      },
      // 删除表单
      onDeleteFormClick(id) {
        let _this = this;
        _this
          .$confirm("确认删除该表单？", "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(function () {
            return deleteForm(id);
          })
          .then(() => {
            _this.$message({
              message: "删除表单成功",
              type: "success",
            });
            _this.$emit("get-refresh-list");
          })
          .catch(function () {});
      },
      // 未发布的表单暂不能分享
      onNoShareClick() {
        this.$message({
          message: "未发布的表单暂不能分享～",
          type: "warning",
        });
      },
      // 点击分享弹框
      async onShareClick(item) {
        let _this = this;
        let id = null;
        let shareType = null;
        const locationUrl = window.location.origin;
        if (item.type == "form") {
          // 分享表单
          _this.getShareNum(item.formId); // 统计分享数量
          id = item.formId;
          shareType = "form";
          if (item.projectId == "0") {
            // 分享应用内的表单
            _this.shareFillUrl =
              locationUrl +
              "/share/" +
              _this.encode(item.formId) +
              "?type=" +
              _this.encode(item.type);
            _this.shareItemInfo = item;
          } else {
            _this.shareFillUrl =
              locationUrl +
              "/share/" +
              _this.encode(item.projectId) +
              "?type=" +
              _this.encode(item.type) +
              "&formId=" +
              _this.encode(item.formId);
            _this.shareItemInfo = item;
          }
        } else {
          // 分享应用
          id = item.projectId;
          shareType = "project";
          _this.shareFillUrl =
            locationUrl +
            "/share/" +
            _this.encode(item.projectId) +
            "?type=" +
            _this.encode(item.type);
          _this.shareItemInfo = item;
        }

        shortLink(_this.shareFillUrl).then((res) => {
          _this.shareFillUrl = res.data;
        });
        _this.getShareImage(id, shareType);
      },
      showSetHomeDialog(item) {
        this.formAgencyVisible = true;
        this.setHomeItem = item;
      },
      onConfirmAgencyClick() {
        // 验证formAgencyObj的rule
        this.$refs["formAgency"].validate((valid) => {
          // valid
          if (valid) {
            // 如果选择的是部分机构成员可见 检查 formAgencyObj.memberList不为空，为空的话提示错误
            if (this.formAgencyObj.type == 2) {
              if (this.formAgencyObj.memberList.length == 0) {
                this.$message({
                  type: "warning",
                  message: "请选择可见人员",
                });
                return false;
              }
            }
            // 关闭弹窗
            this.formAgencyVisible = false;
            // 调用onSetHomeClick
            this.onSetHomeClick(this.setHomeItem);
          } else {
            if (!valid) {
              // 提示 请选择表单置顶可见性
              this.$message({
                type: "warning",
                message: "请选择表单置顶可见性",
              });
            }

            return false;
          }
        });
      },
      onCancelAgencyClick() {
        // 关闭弹窗
        this.formAgencyVisible = false;
      },
      // 置于机构首页
      onSetHomeClick(item) {
        let _this = this;
        setFormAgencyHome({
          agencyId: item.agencyId,
          operation: "place",
          type: item.type,
          typeId: item.type == "form" ? item.formId : item.projectId,
          viewAuthType: "1",
        })
          .then(() => {
            _this.$message({
              type: "success",
              message: "置于机构首页成功",
            });
            _this.$emit("get-refresh-list");
          })
          .catch(function () {});
      },
      // 取消置于机构首页
      onCancelHomeClick(item) {
        let _this = this;
        // 弹出确认窗口
        _this
          .$confirm("确认取消置于机构首页？", "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(function () {
            return setFormAgencyHome({
              agencyId: item.agencyId,
              operation: "cancel",
              type: item.type,
              typeId: item.type == "form" ? item.formId : item.projectId,
              viewAuthType: "1",
            });
          })
          .then(() => {
            _this.$message({
              type: "success",
              message: "取消置于机构首页成功",
            });
            _this.$emit("get-refresh-list");
          })
          .catch(function () {});
      },
      showMemberSelectDialog(val) {
        this.$nextTick(() => {
          this.$refs.memberSelect.show(
            this.formAgencyObj.memberList,
            (members) => {
              this.formAgencyObj.memberList = members;
            }
          );
        });
      },
      // 新版---生成分享图片
      getShareImage(id, type) {
        let _this = this;
        shareImg(id, type).then((res) => {
          if (res.code == 200) {
            _this.shareImageUrl = "data:image/jpg;base64," + res.data;
            _this.shareOpen = true;
          }
        });
      },
      // 新版---添加分享量
      async getShareNum(formId) {
        let _this = this;
        httpShareNum(formId).then((response) => {});
      },
      // 复制填报地址
      async onCopyFillClick() {
        let oInput = document.createElement("input"); //创建input节点
        oInput.value = this.shareFillUrl; //给input的value赋值
        document.body.appendChild(oInput); //向页面插入input节点
        oInput.select(); // 选中input
        try {
          await document.execCommand("Copy"); // 执行浏览器复制命令
          this.$message({
            type: "success",
            message: "复制成功",
          });
        } catch {
          this.$message({
            type: "success",
            message: "复制失败",
          });
        }
        document.body.removeChild(oInput);
      },
      // 直接打开填报地址页面
      onToClick() {
        let tempWindow = window.open("_blank");
        tempWindow.location.href = this.shareFillUrl;
      },
      // 保存小程序码
      onSaveClick() {
        let self = this;
        if (this.isBlank(this.shareItemInfo.shareQr)) {
          this.$message({
            message: "小程序码缺失，暂无法保存",
            type: "warning",
          });
          return;
        }
        this.downloadFile(this.shareItemInfo.name + ".jpg", self.shareImageUrl);
      },
      downloadFile(fileName, content) {
        let aLink = document.createElement("a");
        let blob = this.base64ToBlob(content); //new Blob([content]);

        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        aLink.download = fileName;
        aLink.href = URL.createObjectURL(blob);
        aLink.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        ); //兼容火狐
      },
      // base64转blob
      base64ToBlob(code) {
        let parts = code.split(";base64,");
        let contentType = parts[0].split(":")[1];
        console.log("atob", parts[1]);
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;

        let uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
      },
      // 监听分享弹框关闭
      handleFillDiaClose() {
        this.shareFillUrl = "";
      },
      // 停用、启用表单
      stopOrStart(projectId, stopFlag) {
        let msg = "";
        if (stopFlag) {
          msg = "启用";
        } else {
          msg = "停用";
        }
        let param = {
          projectId: projectId,
          stopFlag: stopFlag ? false : true,
        };
        this.$confirm("是否确认" + msg + "该应用？", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(function () {
            return stopOrStart(param);
          })
          .then(() => {
            this.$emit("get-list");
            this.msgSuccess(msg + "成功");
          })
          .catch(function () {});
      },
      onEditClick(type, projectId) {
        setOssConfig();
        if (type == "1") {
          //单表单
          this.$router.push({
            path: "/form/edit/" + this.encode(projectId),
          });
        } else {
          this.$router.push({
            path: "/form/multipleForm",
            query: {
              projectId: this.encode(projectId),
            },
          });
        }
      },
      // 收藏表单
      onCollectClick(item) {
        let _this = this;
        let param = {
          operation: item.favorite ? "cancel" : "favorite",
          typeId: item.type == "project" ? item.projectId : item.formId,
          type: item.type,
        };
        favorite(param).then((response) => {
          if (response.code == 200) {
            let msg = item.favorite ? "取消收藏" : "收藏成功";
            _this.msgSuccess(msg);
            item.favorite = !item.favorite;
          }
        });
      },
      // 鼠标滑过
      onRowMouseEnter(item) {
        this.$set(item, "showBtn", true);
      },
      // 鼠标离开
      onRowMouseLeave(item) {
        this.$set(item, "showBtn", false);
      },
      onFormMouseEnter(form) {
        this.$set(form, "showBtn", true);
      },
      onFormMouseLeave(form) {
        this.$set(form, "showBtn", false);
      },
      handleFormStopUpdate(data) {
        //     console.log("ssss");
        //     let formDetail=this.list.find(x=>x.formId==data.formId);
        //     console.log(data,formDetail);
        //     formDetail.isStop=data.isStop
      },
      getFormTags(item) {
        return JSON.parse(item.tagList);
      },
    },
  };
</script>

<style lang='scss' scoped>
  .form-img {
    display: inline-block;
    width: 200px;
    height: 120px;
    img {
      width: 100%;
      max-width: 100%;
      height: 120px;
      cursor: pointer;
      border-radius: 6px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
    }
  }
  .form-info {
    width: 100%;
    position: relative;
    height: 120px;
    .form-title {
      width: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 28px;

      .title-span {
        min-width: 200px;
        line-height: 28px;
        font-size: 16px;
        color: #333333;
        flex: 1;
        font-weight: 600;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .form-item-share {
        text-align: right;
        flex-grow: 1;
      }
      .form-tag {
        margin-left: 60px;
        margin-top: 0;
        &.un-start {
          color: #3476f0;
          background-color: #ebf1fe;
          border-color: #d6e4fc;
        }
        &.ing {
          background-color: #effaf5;
          border-color: #dff5eb;
          color: #61cd9d;
        }
        &.stop {
          color: #666666;
          background: #888d931a;
          border-color: #888d9380;
        }
      }
      .form-collect {
        display: inline-block;
        cursor: pointer;
        font-size: 12px;
        color: #909090;
        line-height: 20px;
        .collect-btn {
          color: #646d7a;
          border-color: #dbdde7;
          &.active {
            color: #ffa500;
            // border-color: #FFA500;
          }
          &:hover {
            border-color: rgba(255, 165, 0, 0.8);
            background-color: rgba(255, 165, 0, 0.1);
          }
          .collect-icon {
            margin-right: 5px;
            font-size: 14px;
          }
        }
      }
      .form-share {
        display: inline-block;
        cursor: pointer;
        font-size: 12px;
        color: #909090;
        line-height: 20px;
        margin-left: 10px;
        .share-icon {
          margin-right: 8px;
          font-size: 14px;
        }
      }
    }
    .form-list-bottom {
      position: absolute;
      left: 0;
      width: 100%;
      .form-date {
        width: 100%;
        font-size: 12px;
        margin: 0;
        color: #666666;
        cursor: pointer;
        .fill-date {
          margin: 0;
          padding: 0;
          .is-agency-icon {
            display: inline-block;
            width: 40px;
            height: 16px;
            margin-left: 5px;
            vertical-align: -3px;
          }
          .form-type-icon {
            display: inline-block;
            font-size: 16px;
            margin-right: 5px;
            vertical-align: -3px;
          }
          &-label {
            width: 50px;
            display: inline-block;
            text-align-last: justify;
            text-align: justify;
            text-justify: distribute-all-lines;
          }
        }
        .form-create {
          font-size: 12px;
          line-height: 22px;
          .form-settings {
            font-size: 12px;
            text-align: right;
            span {
              cursor: pointer;
              padding: 0 10px;
              border-right: 1px solid #707070;
              color: #409eff;
              img {
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-right: 5px;
                vertical-align: middle;
              }
              &:last-child {
                border-right: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
  .list-no-data {
    width: 100%;
    margin: 130px auto 230px;
    text-align: center;
    .no-data-img {
      display: block;
      width: 370px;
      height: auto;
      margin: auto;
    }
    .no-data-text {
      display: block;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      color: #909090;
      margin-top: 37px;
    }
  }
  .share-container {
    margin-top: -30px;
    width: 100%;
    p {
      padding: 0;
      margin: 0 auto;
    }
    .shareFillImg {
      display: block;
      width: 200px;
      height: 200px;
      margin-top: 20px;
    }
    .share-lite-contain {
      width: 380px;
      height: 550px;
      background: url("https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/canvas_default.png")
        no-repeat top center;
      background-size: 380px 550px;
      padding-top: 63px;
      margin: auto;
      .top-logo {
        width: 100%;
        padding: 25px 0 15px 40px;
        box-sizing: border-box;
        img {
          display: inline-block;
          vertical-align: middle;
          width: 107px;
          height: 29px;
        }
      }
      .share-card {
        width: calc(100% - 50px);
        height: 470px;
        margin: 0 25px 25px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 20px 0;
        position: relative;
        border-radius: 10px;
        .share-form-img {
          display: inline-block;
          vertical-align: middle;
          width: 100%;
          height: 166px;
          margin: 20px auto 5px;
          border-radius: 10px;
        }
        .share-form-name {
          width: 100%;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #404040;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .share-form-fill {
          font-size: 12px;
          line-height: 20px;
          color: #909090;
          margin-left: 70px;
          margin-top: 4px;
        }
        .share-form-bottom {
          width: 100%;
          margin-top: 25px;
          .share-form-qr {
            width: 140px;
            height: 140px;
            margin: auto;
            text-align: center;
            display: block;
          }
          .share-form-qr-text {
            width: 100%;
            font-size: 11px;
            line-height: 16px;
            color: #394349;
            text-align: center;
            display: block;
            margin-top: 10px;
          }
        }
      }
    }
    .list-share-image {
      width: 380px;
      height: 550px;
      display: block;
      margin: auto;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
  }
  .arrow-right {
    display: inline-block;
    width: 37px;
    height: 110px;
    background: url("https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/arrow-bottom.png")
      no-repeat center center;
    background-size: 19px 33px;
  }
  .arrow-bottom {
    display: inline-block;
    width: 100%;
    height: 33px;
    background: url("https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/arrow-top.png")
      no-repeat center center;
    background-size: 33px 19px;
  }
  .project-detail-img {
    img {
      width: 100%;
      max-width: 100%;
      height: 152px;
      border-radius: 6px;
    }
  }
  .project-detail-info {
    .project-detail-title {
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      color: #394349;
      margin: 0;
      line-height: 25px;
      span {
        max-width: 100%;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .project-detail-date {
      width: 100%;
      font-size: 13px;
      color: #394349;
      margin: 15px 0 0;
    }
    .project-detail-creater {
      width: 100%;
      font-size: 13px;
      color: #394349;
      margin: 10px 0 0;
      cursor: pointer;
      p {
        margin: 0;
        padding: 0;
        .creater-btn {
          color: #0078f8;
          border-right: 1px solid #0078f8;
          padding: 0 10px;
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
  .project-intro-title {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 60px 0 0;
    .title-span {
      font-size: 13px;
      font-weight: 600;
      color: #394349;
      line-height: 18px;
    }
    .title-line {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      -moz-flex: 1;
      flex: 1;
      height: 1px;
      background: #e5e5e5;
      margin-left: 20px;
    }
  }
  .project-intro-con {
    width: 100%;
    font-size: 13px;
    line-height: 22px;
    margin-top: 30px;
    color: #394349;
    word-break: break-all;
  }
  .canvas {
    top: 100px;
  }
  .select-list {
    height: 80px;
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
    overflow: auto;
    margin-top: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      line-height: 30px;
      margin: 5px 0 0 5px;
      padding: 0 10px;
      border-radius: 1px;
      background: #f3f6fc;
      cursor: pointer;
    }
  }
  // @media screen and (max-width: 1440px) {
  //   .form-info .form-title .form-tag{
  //     margin-left: 20px;
  //   }
  // }
  .card-box {
    position: relative;
    border-radius: 8px;
    height: 150px;
    border: 1px solid #e9ebf0;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      // box-shadow: var(--shadow-1, 0 3px 8px 0 rgba(0, 0, 0, 0.03));
      -webkit-box-shadow: 0 6px 24px 0 rgba(0,0,0,.1);
      box-shadow: 0 6px 24px 0 rgba(0,0,0,.1);
      -webkit-box-shadow: var(--shadow-3,0 6px 24px 0 rgba(0,0,0,.1));
      box-shadow: var(--shadow-3,0 6px 24px 0 rgba(0,0,0,.1));
    }

    .imgBx {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.5s;
        border-radius: 8px;
      }
    }

    .header {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      // height: 40px;
      text-align: right;
      transition: 0.5s;
      overflow: hidden;
      padding: 5px;
      box-sizing: border-box;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;

      .form-type-icon {
        font-size: 20px;
        margin: 0;
        opacity: 0.7;
      }
    }
    .content {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0px;
      height: 40px;
      background: #ffffff;
      transition: 0.5s;
      overflow: hidden;
      padding: 10px 5px;
      box-sizing: border-box;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;

      h3 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        // text-align: center;
      }
      p {
        margin: 30px 0 0 0;
        padding: 0;
        opacity: 0;
        line-height: 1.6em;
        transition: 0.5s;
        text-align: justify;
        a {
          color: #333;
          text-decoration: none;
        }
      }
      i {
        padding: 0 2px;
      }
    }
  }
  .card-box:hover .content {
    height: 50%;
    bottom: 0;
    left: 0;
    border-radius: 8px;
  }
  .card-box:hover .content p {
    opacity: 1;
    transition-delay: 0.2s;
  }
  .card-box:hover .content i {
    padding: 0 2px;
    opacity: 1;
    color: #CCCCCC;
    transition-delay: 0.2s;
  }
  .card-box:hover .content i:hover {
    color: #3476f0;
  }

  .card-box-icon-show {
    opacity: 1;
  }
  .card-box-icon-hide {
    opacity: 0;
  }

  .card-edit-links {
    ::v-deep .form-settings {
      text-align: right;
      // float: left;
      .split-button {
        padding: 0 10px 0 0;
      }
    }
  }
</style>
