var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form" },
    [
      !_vm.isShowListError
        ? _c("el-row", { staticClass: "list-content" }, [
            _vm.listStyle === "list"
              ? _c(
                  "div",
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "el-row",
                      {
                        key: index,
                        staticClass: "el-row",
                        staticStyle: {
                          margin: "0",
                          padding: "20px 0px",
                          "border-bottom": "2px solid #F8F9FC",
                          "box-sizing": "border-box",
                        },
                        attrs: { gutter: 0, type: "flex" },
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              padding: "0",
                              width: "200px",
                              height: "120px",
                            },
                            attrs: { span: 4, xs: 24 },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "form-img",
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetailClick(item)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: item.cover
                                      ? item.cover +
                                        "?x-oss-process=image/resize,m_fixed,h_300,w_500"
                                      : item.type === "project"
                                      ? _vm.projectDefaultHeadImg +
                                        "?x-oss-process=image/resize,m_fixed,h_300,w_500"
                                      : _vm.formDefaultHeadImg +
                                        "?x-oss-process=image/resize,m_fixed,h_300,w_500",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "padding-left": "0",
                              "padding-right": "0",
                              "margin-left": "20px",
                              cursor: "pointer",
                            },
                            attrs: { span: 20, xs: 24 },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "form-info",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.onRowMouseEnter(item)
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.onRowMouseLeave(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-title" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "title-span",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDetailClick(item)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    !item.isStop
                                      ? _c(
                                          "el-tag",
                                          {
                                            staticClass: "form-tag stop",
                                            attrs: {
                                              size: "mini",
                                              type: "info",
                                            },
                                          },
                                          [_vm._v("已暂停")]
                                        )
                                      : item.type !== "project" &&
                                        item.formStateTxt
                                      ? _c(
                                          "el-tag",
                                          {
                                            staticClass: "form-tag",
                                            class: _vm.getTagClass(
                                              item.formStateTxt
                                            ),
                                            attrs: { size: "mini" },
                                          },
                                          [_vm._v(_vm._s(item.formStateTxt))]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "fr form-item-share" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-collect",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onCollectClick(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "collect-btn",
                                                class: {
                                                  active: item.favorite,
                                                },
                                                attrs: { size: "mini" },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass: "collect-icon",
                                                  attrs: {
                                                    "icon-class": item.favorite
                                                      ? "formListCollect"
                                                      : "formListUnCollect",
                                                  },
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.favorite
                                                        ? "已收藏"
                                                        : "收藏"
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        item.status == "1"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "form-share",
                                                on: {
                                                  click: _vm.onNoShareClick,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      disabled: "",
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticClass: "share-icon",
                                                      attrs: {
                                                        "icon-class":
                                                          "formListShare",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("分享"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "form-share",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onShareClick(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  { attrs: { size: "mini" } },
                                                  [
                                                    _c("svg-icon", {
                                                      staticClass: "share-icon",
                                                      attrs: {
                                                        "icon-class":
                                                          "formListShare",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("分享"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    ),
                                    _vm.cur === 1 &&
                                    item.userId === _vm.loginUserId
                                      ? _c(
                                          "div",
                                          { staticClass: "form-share" },
                                          [
                                            item.agencyHome == 0
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showSetHomeDialog(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticClass: "share-icon",
                                                      attrs: {
                                                        "icon-class": "home-o",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("置于机构首页"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.agencyHome == 1
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onCancelHomeClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticClass: "share-icon",
                                                      attrs: {
                                                        "icon-class": "home-o",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v(
                                                        "取消置于机构首页"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm.curRoutePath === "/index" &&
                                        item.userId === _vm.loginUserId
                                      ? _c(
                                          "div",
                                          { staticClass: "form-share" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { size: "mini" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onCancelHomeClick(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass: "share-icon",
                                                  attrs: {
                                                    "icon-class": "home-o",
                                                  },
                                                }),
                                                _c("span", [
                                                  _vm._v("取消置于机构首页"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-list-bottom",
                                    style: {
                                      bottom:
                                        _vm.cur == 1
                                          ? item.type == "form"
                                            ? "-6px"
                                            : "-10px"
                                          : "0px",
                                    },
                                  },
                                  [
                                    _vm.cur == 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "form-date",
                                            style: [
                                              {
                                                lineHeight:
                                                  _vm.cur == 1
                                                    ? item.type == "form"
                                                      ? "22px"
                                                      : "30px"
                                                    : "",
                                              },
                                              {
                                                height:
                                                  _vm.cur == 1
                                                    ? item.type == "form"
                                                      ? "22px"
                                                      : "30px"
                                                    : "",
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.onDetailClick(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "fill-date" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "fill-date-label",
                                                  },
                                                  [_vm._v("创建时间")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      margin: "0 10px",
                                                    },
                                                  },
                                                  [_vm._v(":")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.createTime)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.type == "form"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "form-date",
                                            style: [
                                              {
                                                lineHeight:
                                                  _vm.cur == 1
                                                    ? item.type == "form"
                                                      ? "22px"
                                                      : "30px"
                                                    : "30px",
                                              },
                                              {
                                                height:
                                                  _vm.cur == 1
                                                    ? item.type == "form"
                                                      ? "22px"
                                                      : "30px"
                                                    : "30px",
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.onDetailClick(item)
                                              },
                                            },
                                          },
                                          [
                                            item.fillStartTime ||
                                            item.fillEndTime ||
                                            (item.fillStartTime &&
                                              item.fillEndTime)
                                              ? [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "fill-date",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fill-date-label",
                                                        },
                                                        [_vm._v("填报时间")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            margin: "0 10px",
                                                          },
                                                        },
                                                        [_vm._v(":")]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.fillStartTime
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.fillStartTime &&
                                                                item.fillEndTime,
                                                              expression:
                                                                "item.fillStartTime && item.fillEndTime",
                                                            },
                                                          ],
                                                          staticStyle: {
                                                            margin: "0 5px",
                                                          },
                                                        },
                                                        [_vm._v(" - ")]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.fillEndTime
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            !item.fillStartTime &&
                                            !item.fillEndTime
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "fill-date",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fill-date-label",
                                                        },
                                                        [_vm._v("填报时间")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            margin: "0 10px",
                                                          },
                                                        },
                                                        [_vm._v(":")]
                                                      ),
                                                      _c("span", [
                                                        _vm._v("——"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-date",
                                        style: [
                                          {
                                            lineHeight:
                                              _vm.cur == 1
                                                ? item.type == "form"
                                                  ? "22px"
                                                  : "30px"
                                                : "30px",
                                          },
                                          {
                                            height:
                                              _vm.cur == 1
                                                ? item.type == "form"
                                                  ? "22px"
                                                  : "30px"
                                                : "30px",
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDetailClick(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "fill-date" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "fill-date-label",
                                              },
                                              [_vm._v("创建人")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  margin: "0 10px",
                                                },
                                              },
                                              [_vm._v(":")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.founder
                                                    ? item.founder
                                                    : item.createName
                                                )
                                              ),
                                            ]),
                                            item.agencyId
                                              ? _c("img", {
                                                  staticClass: "is-agency-icon",
                                                  attrs: {
                                                    src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                                  },
                                                })
                                              : _c("span", [
                                                  _vm._v("（个人）"),
                                                ]),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  float: "right",
                                                  display: "inline-block",
                                                },
                                              },
                                              [
                                                _c("formTagEditor", {
                                                  attrs: {
                                                    readonly: true,
                                                    value:
                                                      _vm.getFormTags(item),
                                                    tagList: _vm.tagList,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-date",
                                        style: [
                                          {
                                            lineHeight:
                                              _vm.cur == 1
                                                ? item.type == "form"
                                                  ? "22px"
                                                  : "30px"
                                                : "30px",
                                          },
                                          {
                                            height:
                                              _vm.cur == 1
                                                ? item.type == "form"
                                                  ? "22px"
                                                  : "30px"
                                                : "30px",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "fill-date fl",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onDetailClick(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "fill-date-label",
                                              },
                                              [_vm._v("类型")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  margin: "0 10px",
                                                },
                                              },
                                              [_vm._v(":")]
                                            ),
                                            item.type === "project"
                                              ? _c("svg-icon", {
                                                  staticClass: "form-type-icon",
                                                  attrs: {
                                                    "icon-class":
                                                      "formListProject",
                                                  },
                                                })
                                              : _c("svg-icon", {
                                                  staticClass: "form-type-icon",
                                                  attrs: {
                                                    "icon-class":
                                                      "formListForm",
                                                  },
                                                }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.type == "project"
                                                    ? "应用"
                                                    : "表单"
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.showBtn,
                                                expression: "item.showBtn",
                                              },
                                            ],
                                            staticClass: "form-create fr",
                                            style: [
                                              {
                                                lineHeight:
                                                  _vm.cur == 1
                                                    ? item.type == "form"
                                                      ? "22px"
                                                      : "30px"
                                                    : "30px",
                                              },
                                              {
                                                height:
                                                  _vm.cur == 1
                                                    ? item.type == "form"
                                                      ? "22px"
                                                      : "30px"
                                                    : "30px",
                                              },
                                            ],
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fr form-settings",
                                                attrs: {
                                                  vif: "item.userId == loginUserId",
                                                },
                                              },
                                              [
                                                _c("editLinks", {
                                                  attrs: {
                                                    formDetail: item,
                                                    loginUserId:
                                                      _vm.loginUserId,
                                                    loginAgencyUserId:
                                                      _vm.loginAgencyUserId,
                                                    formType: item.type,
                                                    returnType: "refresh",
                                                  },
                                                  on: {
                                                    refreshList:
                                                      _vm.refreshList,
                                                    formStopUpdate:
                                                      _vm.handleFormStopUpdate,
                                                    editProject:
                                                      _vm.onEditProjectClick,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm.listStyle === "card"
              ? _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 16 } },
                      _vm._l(_vm.list, function (item, index) {
                        return _c(
                          "el-col",
                          {
                            key: index,
                            staticStyle: { "margin-top": "10px" },
                            attrs: { xs: 12, sm: 12, md: 8, lg: 6, xl: 6 },
                          },
                          [
                            _c("div", { staticClass: "card-box" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "imgBx",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetailClick(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: item.cover
                                        ? item.cover +
                                          "?x-oss-process=image/resize,m_fixed,h_300,w_500"
                                        : item.type === "project"
                                        ? _vm.projectDefaultHeadImg +
                                          "?x-oss-process=image/resize,m_fixed,h_300,w_500"
                                        : _vm.formDefaultHeadImg +
                                          "?x-oss-process=image/resize,m_fixed,h_300,w_500",
                                    },
                                  }),
                                ]
                              ),
                              _c("div", { staticClass: "content" }, [
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticStyle: { float: "left" } },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: item.name,
                                            placement: "top",
                                            "popper-class": "tips",
                                          },
                                        },
                                        [
                                          _c(
                                            "h3",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onDetailClick(item)
                                                },
                                              },
                                            },
                                            [
                                              item.type === "project"
                                                ? _c("svg-icon", {
                                                    staticClass:
                                                      "form-type-icon",
                                                    staticStyle: {
                                                      margin: "0",
                                                    },
                                                    attrs: {
                                                      "icon-class":
                                                        "formListProject",
                                                    },
                                                  })
                                                : _c("svg-icon", {
                                                    staticClass:
                                                      "form-type-icon",
                                                    staticStyle: {
                                                      margin: "0",
                                                    },
                                                    attrs: {
                                                      "icon-class":
                                                        "formListForm",
                                                    },
                                                  }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.name.length >= 9
                                                      ? item.name.slice(0, 9) +
                                                          "..."
                                                      : item.name
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "icon",
                                      staticStyle: { float: "right" },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: "分享",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          item.status == "1"
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-share card-box-icon-hide",
                                                on: {
                                                  click: _vm.onNoShareClick,
                                                },
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "el-icon-share card-box-icon-hide",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onShareClick(
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                        ]
                                      ),
                                      item.userId === _vm.loginUserId
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      item.agencyHome == 0
                                                        ? "置于机构首页"
                                                        : "取消置于机构首页",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _vm.cur === 1 &&
                                                  item.agencyHome == 0
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-s-flag card-box-icon-hide",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showSetHomeDialog(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  item.agencyHome == 1 ||
                                                  _vm.curRoutePath === "/index"
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-s-flag card-box-icon-show",
                                                        staticStyle: {
                                                          color: "#f7ba2a",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onCancelHomeClick(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: item.favorite
                                              ? "取消收藏"
                                              : "收藏",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            class: item.favorite
                                              ? "el-icon-star-on card-box-icon-show"
                                              : "el-icon-star-off card-box-icon-hide",
                                            style: {
                                              color: item.favorite
                                                ? "#f7ba2a"
                                                : "",
                                              fontSize: item.favorite
                                                ? "19px"
                                                : "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onCollectClick(item)
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "p",
                                  [
                                    _c("editLinks", {
                                      staticClass: "card-edit-links",
                                      attrs: {
                                        formDetail: item,
                                        loginUserId: _vm.loginUserId,
                                        loginAgencyUserId:
                                          _vm.loginAgencyUserId,
                                        formType: item.type,
                                        returnType: "refresh",
                                      },
                                      on: {
                                        refreshList: _vm.refreshList,
                                        formStopUpdate:
                                          _vm.handleFormStopUpdate,
                                        editProject: _vm.onEditProjectClick,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : [
            _vm.cur == 1
              ? _c("div", { staticClass: "list-no-data" }, [
                  _c("img", {
                    staticClass: "no-data-img",
                    attrs: {
                      src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list_create_no.png",
                    },
                  }),
                  _c("span", { staticClass: "no-data-text" }, [
                    _vm._v("快去创建你的应用或者表单吧~"),
                  ]),
                ])
              : _vm._e(),
            _vm.cur == 2
              ? _c("div", { staticClass: "list-no-data" }, [
                  _c("img", {
                    staticClass: "no-data-img",
                    attrs: {
                      src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list_fill_no.png",
                    },
                  }),
                  _c("span", { staticClass: "no-data-text" }, [
                    _vm._v("你还没有填报数据哦~"),
                  ]),
                ])
              : _vm._e(),
            _vm.cur == 3
              ? _c("div", { staticClass: "list-no-data" }, [
                  _c("img", {
                    staticClass: "no-data-img",
                    attrs: {
                      src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list_review_no.png",
                    },
                  }),
                  _c("span", { staticClass: "no-data-text" }, [
                    _vm._v("你还没有审核数据哦~"),
                  ]),
                ])
              : _vm._e(),
            _vm.cur == 4
              ? _c("div", { staticClass: "list-no-data" }, [
                  _c("img", {
                    staticClass: "no-data-img",
                    attrs: {
                      src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list_manage_no.png",
                    },
                  }),
                  _c("span", { staticClass: "no-data-text" }, [
                    _vm._v("你还没有管理数据哦~"),
                  ]),
                ])
              : _vm._e(),
          ],
      _c(
        "el-dialog",
        {
          attrs: {
            title: "填报地址",
            visible: _vm.shareOpen,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.shareOpen = $event
            },
            close: _vm.handleFillDiaClose,
          },
        },
        [
          _c("div", { staticClass: "share-container" }, [
            _c(
              "h5",
              {
                staticStyle: {
                  "font-size": "20px",
                  color: "#404040",
                  margin: "20px 0 10px",
                },
              },
              [_vm._v("链接分享")]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "12px",
                  color: "#909090",
                  "margin-bottom": "10px",
                },
              },
              [_vm._v("将填报地址粘贴到浏览器访问，或者直接发给填报者")]
            ),
            _c(
              "div",
              { staticStyle: { width: "100%", height: "auto" } },
              [
                _c("el-input", {
                  staticClass: "fl",
                  staticStyle: { width: "60%", "margin-right": "40px" },
                  attrs: {
                    type: "text",
                    value: _vm.shareFillUrl,
                    readonly: "",
                  },
                }),
                _c(
                  "p",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onCopyFillClick },
                      },
                      [_vm._v("复制链接")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "20px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.onToClick },
                      },
                      [_vm._v("直接打开")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "h5",
              {
                staticStyle: {
                  "font-size": "20px",
                  color: "#404040",
                  margin: "20px 0 10px",
                },
              },
              [_vm._v("小程序码分享")]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "12px",
                  color: "#909090",
                  "margin-bottom": "10px",
                },
              },
              [_vm._v("选择小程序码样式，保存小程序码直接发给填报者")]
            ),
            _c("img", {
              staticClass: "list-share-image",
              attrs: { src: _vm.shareImageUrl },
            }),
            _c(
              "div",
              {
                staticStyle: {
                  width: "398px",
                  margin: "auto",
                  "text-align": "center",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "20px auto 0" },
                    attrs: { type: "primary" },
                    on: { click: _vm.onSaveClick },
                  },
                  [_vm._v("保存小程序码")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "移动表单",
            visible: _vm.formMoveVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formMoveVisible = $event
            },
            close: _vm.onCloseMoveClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formMove",
              attrs: {
                model: _vm.formMoveObj,
                rules: _vm.formMoveRules,
                "label-width": "100px",
                "label-position": "right",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所移动应用", prop: "projectId" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择移动应用",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formMoveObj.projectId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formMoveObj, "projectId", $$v)
                            },
                            expression: "formMoveObj.projectId",
                          },
                        },
                        _vm._l(_vm.moveFormProjectList, function (item) {
                          return _c("el-option", {
                            key: item.projectId,
                            attrs: { label: item.name, value: item.projectId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmMoveClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelMoveClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择置顶可见性",
            visible: _vm.formAgencyVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formAgencyVisible = $event
            },
            close: _vm.onCancelAgencyClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formAgency",
              attrs: {
                model: _vm.formAgencyObj,
                rules: _vm.formAgencyRules,
                "label-width": "100px",
                "label-position": "right",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "置顶可见性", prop: "agencyVisible" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择置顶可见性",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formAgencyObj.agencyVisible,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAgencyObj, "agencyVisible", $$v)
                            },
                            expression: "formAgencyObj.agencyVisible",
                          },
                        },
                        _vm._l(_vm.agencyVisibleList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formAgencyObj.agencyVisible == 2
                    ? _c("div", { staticStyle: { "margin-top": "50px" } }, [
                        _vm.formAgencyObj.memberList.length > 0
                          ? _c(
                              "ul",
                              {
                                staticClass: "select-list",
                                on: { click: _vm.showMemberSelectDialog },
                              },
                              _vm._l(
                                _vm.formAgencyObj.memberList,
                                function (item, index) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "select-item" },
                                    [
                                      item.type == "member"
                                        ? [
                                            _c("svg-icon", {
                                              staticStyle: {
                                                width: "20px",
                                                height: "20px",
                                                "vertical-align": "-5px",
                                                "margin-right": "5px",
                                              },
                                              attrs: {
                                                "icon-class": "memberIcon",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      item.type == "dept"
                                        ? [
                                            _c("svg-icon", {
                                              staticStyle: {
                                                width: "20px",
                                                height: "20px",
                                                "vertical-align": "-5px",
                                                "margin-right": "5px",
                                              },
                                              attrs: {
                                                "icon-class": "deptIcon",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      item.type == "role"
                                        ? [
                                            _c("svg-icon", {
                                              staticStyle: {
                                                width: "20px",
                                                height: "20px",
                                                "vertical-align": "-5px",
                                                "margin-right": "5px",
                                              },
                                              attrs: {
                                                "icon-class": "roleIcon",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _c("span", [_vm._v(_vm._s(item.name))]),
                                    ],
                                    2
                                  )
                                }
                              ),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "designated-member",
                                on: { click: _vm.showMemberSelectDialog },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "member-empty" },
                                  [
                                    _c("svg-icon", {
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                        "margin-right": "5px",
                                        color: "#5e6d82",
                                        "vertical-align": "-5px",
                                      },
                                      attrs: { "icon-class": "add" },
                                    }),
                                    _vm._v("点击添加指定成员 "),
                                  ],
                                  1
                                ),
                              ]
                            ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("member-select", {
                ref: "memberSelect",
                attrs: { agencyId: _vm.setHomeItem.agencyId, showType: [1] },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmAgencyClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelAgencyClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }