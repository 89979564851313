<template>

  <el-dialog  :title="projectId == null ? '创建应用' : '修改应用'" :visible.sync="newProjectOpen" width="800px" append-to-body @opened="onShowNewProjectDialog" :before-close="onCloseNewProjectDialog" custom-class="project-detail-edit-dialog">
    <el-form size="small" ref="projectForm" :model="project" :rules="projectRules" label-width="90px" label-position="right" @submit.native.prevent>
      <el-form-item label="应用名称" prop="name">
        <el-col :span="24">
          <el-input v-model="project.name" placeholder="请输入应用名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="封面图片" prop="topImg">
        <el-col :span="8" style="padding: 0; width: 200px; height: 120px;">
          <el-popover placement="bottom" title="请选择一个封面" width="500" trigger="click">
            <div style="height: 252px; overflow-y: auto;">
              <img v-for="(src,index) in defaultCover" :src="src" :key="index" @click="project.cover = src" style="width: 90px;height: 60px;padding: 2px 5px;border-radius: 8px; cursor: pointer;">
            </div>
            <div style="width: 100%;height: 1px; border: 0.5px solid #e8e8e8;margin: 10px 0;"></div>
            <div class="uploadCover" @click="editCropper">
              <div class="uploadCover-icon"><i class="el-icon-upload"></i></div>
              <div>
                <div class="uploadCover-title">上传封面</div>
                <div style="color: #cecece; font-size: 12px;">600*360px（JPG或PNG）最大2M</div>
              </div>
            </div>
            <div slot="reference" style="width: 200px; height: 120px;">
              <div class="uploader-hover"></div>
              <img :src="project.cover" class="cover" />
            </div>
          </el-popover>
        </el-col>
        <!-- <el-col class="line" :span="10">
            <span>建议上传高清图片</span>
            <span>仅支持格式：JPG、PNG、JPEG</span>
            <span>建议上传尺寸： 600 * 360</span>
          </el-col> -->
      </el-form-item>
      <el-form-item label="标签">
        <formTagEditor v-model="project.tagList" :readonly="false" :tagList="tagList"></formTagEditor>
      </el-form-item>
      <el-form-item label="应用介绍" prop="details">
        <!-- <div id="wangEditor"></div> -->
        <richEditor formId="" :agencyId="user.adminAgencyId" uploadPath="fillFile/{agencyId}/appDescription/" v-model="project.details"></richEditor>
      </el-form-item>
      <el-form-item label="相关附件" prop="fileList" v-if="newProjectOpen">
        <div style="display:inline-block;">
          <el-upload ref="uploader" class="upload-demo" :headers="{Authorization: Authorization}" action="/api/ali/upload" multiple :data="uploadParam" :auto-upload="true" :show-file-list="false" :file-list="project.fileList" :limit='5' :http-request="formFilesUpload.elFileUpload"
                     :before-upload="onBeforeFileUpload" :on-exceed="onHandleFileExceed" :on-success="onHandleFileSuccess" :before-remove="(params) => onBeforeFileRemove(params)">
            <el-button style="border-color: #3476F0;color: #3476F0;" icon="el-icon-plus" size="mini">添加附件</el-button>
          </el-upload>
        </div>
        <fileUploadList :showSize="false" :uploader="uploaderRef" v-model="fileList"></fileUploadList>
      </el-form-item>

      <el-form-item label="启用设计">
        <el-switch v-model="project.enableDesign" active-color="#13ce66" />
      </el-form-item>
      <designView ref="designView" v-if="project.enableDesign" :type="'1'" :businessId="projectId"></designView>

      <el-form-item label="分享图标">
        <el-upload v-if="!project.shareIcon" style="text-align: left" ref="shareUploader" :headers="{ Authorization: Authorization }" action="/oss/endpoint/put-file" multiple :data="uploadParam" :auto-upload="true" :show-file-list="false" :file-list="shareFileList" :limit="1"
                   :http-request="shareFilesUpload.elFileUpload" :on-success="onHandleShareFileSuccess">
          <el-button style="border-color: #3476f0; color: #3476f0" icon="el-icon-plus" size="mini">上传</el-button>
        </el-upload>
        <div class="preview-share-icon" v-if="project.shareIcon" :style="{backgroundImage: `url('${project.shareIcon}')`}">
          <span class="el-icon-circle-close" @click="clearShareIcon()"></span>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitNewProjectClick">确 定</el-button>
      <el-button @click="onCloseNewProjectDialog">取 消</el-button>
    </div>
    <el-dialog title="修改应用封面图片" :visible.sync="open" width="1000px" append-to-body :close-on-click-modal="false">
      <ImgCutter ref='imgCutterModal' :isModal="false" :boxWidth="500" :boxHeight="400" :cutWidth="300" :cutHeight="204" :sizeChange="true" :moveAble="true" :tool="false" :previewMode="true" :crossOrigin="true" crossOriginHeader="*" :scaleAble="false" :imgMove="false" rate="5:3"
                 @cutDown="onCutDownCover" @onPrintImg="onPrintImg" @onClearAll="onClearAll"></ImgCutter>
      <div style="position: absolute;right: 20px;top: 85px; border: 1px solid #eee; width: 450px; height: 400px; display: flex; align-items: center; justify-content: center">
        <img :src="cutDownUrl" style="display: inline-block;width: auto;height: auto;float: right;" />
      </div>
    </el-dialog>
  </el-dialog>

</template>
<script>

import ImgCutter from 'vue-img-cutter'
import { putFile, setOssConfig } from "@/utils/ossUtils";
import richEditor from "@/components/RichEditor";
import formTagEditor from "@/views/system/form/components/formTag/editor.vue";
import fileUploadList from "@/views/system/form/components/fileUploadList.vue";
import designView from "@/views/system/form/components/designView.vue";
import { getSessionStorageObj, setSessionStorageObj } from '@/utils/db'
import {
  projectList,
  project,
  projectDetail,
  editProject,
  getTagListByUserId,
  createFormByTemplate,
  createProjectByTemplate,
} from "@/api/system/form";
import { aliUploader } from "@/utils/oss";
export default {
  components: {
    ImgCutter,
    richEditor,
    designView,
    formTagEditor,
    fileUploadList
  },
  props: {
    tagList: {
      type: Array,
      default: () => []
    },
    user: {
      type: Object,
      default: () => { }
    }
  },
  watch: {
    "project.fileList": {
      deep: true,
      handler () {
        this.fileList = this.project.fileList;
      },
    }
  },
  data () {
    return {
      // 创建应用相关
      projectId: "",
      cutDownUrl: null,
      newProjectOpen: false,
      open: false,
      fileList: [],
      project: {
        name: "",
        cover: undefined,
        details: "",
        fileList: [],
        shareIcon: '',
        enableDesign: false
      },
      formFilesUpload: null,
      defaultCover: [
        require(`@/assets/cover/cover-1.jpg`),
        require(`@/assets/cover/cover-2.jpg`),
        require(`@/assets/cover/cover-3.jpg`),
        require(`@/assets/cover/cover-4.jpg`),
        require(`@/assets/cover/cover-5.jpg`),
        require(`@/assets/cover/cover-6.jpg`),
        require(`@/assets/cover/cover-7.jpg`),
        require(`@/assets/cover/cover-8.jpg`),
        require(`@/assets/cover/cover-9.jpg`),
        require(`@/assets/cover/cover-10.jpg`),
        require(`@/assets/cover/cover-11.jpg`),
        require(`@/assets/cover/cover-12.jpg`),
        require(`@/assets/cover/cover-13.jpg`),
        require(`@/assets/cover/cover-14.jpg`),
        require(`@/assets/cover/cover-15.jpg`),
        require(`@/assets/cover/cover-16.jpg`),
        require(`@/assets/cover/cover-17.jpg`),
        require(`@/assets/cover/cover-18.jpg`),
        require(`@/assets/cover/cover-19.jpg`),
        require(`@/assets/cover/cover-20.jpg`),
        require(`@/assets/cover/cover-21.jpg`),
        require(`@/assets/cover/cover-22.jpg`),
        require(`@/assets/cover/cover-23.jpg`),
        require(`@/assets/cover/cover-24.jpg`),
        require(`@/assets/cover/cover-25.jpg`),
        require(`@/assets/cover/cover-26.jpg`),
        require(`@/assets/cover/cover-27.jpg`),
        require(`@/assets/cover/cover-28.jpg`),
        require(`@/assets/cover/cover-29.jpg`),
        require(`@/assets/cover/cover-30.jpg`),
        require(`@/assets/cover/cover-31.jpg`),
        require(`@/assets/cover/cover-32.jpg`),
        require(`@/assets/cover/cover-33.jpg`),
        require(`@/assets/cover/cover-34.jpg`),
        require(`@/assets/cover/cover-35.jpg`),
        require(`@/assets/cover/cover-36.jpg`),
        require(`@/assets/cover/cover-37.jpg`),
        require(`@/assets/cover/cover-38.jpg`),
        require(`@/assets/cover/cover-39.jpg`),
        require(`@/assets/cover/cover-40.jpg`),
        require(`@/assets/cover/cover-41.jpg`),
        require(`@/assets/cover/cover-42.jpg`),
        require(`@/assets/cover/cover-43.jpg`),
        require(`@/assets/cover/cover-44.jpg`),
        require(`@/assets/cover/cover-45.jpg`),
        require(`@/assets/cover/cover-46.jpg`),
        require(`@/assets/cover/cover-47.jpg`),
        require(`@/assets/cover/cover-48.jpg`),
        require(`@/assets/cover/cover-49.jpg`),
        require(`@/assets/cover/cover-50.jpg`)
      ],
      Authorization: "",
      projectRules: {
        name: [
          {
            required: true,
            message: "应用名称不能为空",
            trigger: ["change", "blur"],
          },
          {
            min: 1,
            max: 50,
            message: "应用名称在 1 到 50 个字符",
            trigger: ["change", "blur"],
          },
        ],
      },
      // 上传附件传值给后台参数
      uploadParam: {
        folder: "formFile",
      },
      shareFileList: [],
      shareFilesUpload: null,
    }
  },
  created () {
    this.formFilesUpload = aliUploader(
      false,
      {
        uploadPath: "fillFile/{agencyId}/{formId}/formInfoFiles/",
      },
      () => {
        return this.project.fileList;
      }
    );
    this.shareFilesUpload = aliUploader(
      false,
      {
        formId: this.formId,
        uploadPath:
          "fillFile/{agencyId}/{formId}/formInfoFiles/",
      },
      () => {
        return this.shareFileList;
      }
    );
  },
  methods: {
    // 远程、跨域裁剪（兼容IE9）
    editCropper () {
      const _this = this;
      _this.open = true;
      setOssConfig();
      _this.$nextTick(function () {
        _this.$refs.imgCutterModal.handleOpen({
          name: "project_default.jpeg",
          src: _this.project.cover,
        });
      });
    },
    async getTagListByUserId () {
      let res = await getTagListByUserId();
      this.$emit('update:tagList', res.data.tagList);
    },
    onSubmitNewProjectClick () {
      this.$refs["projectForm"].validate(async (valid) => {
        if (valid) {

          if (this.project.enableDesign) {
            await this.$refs.designView.saveDesign()
          }


          if (!this.isBlank(this.projectId)) {
            this.httpEditProject();
          } else {
            this.httpCreateProject();
          }
        }
      });
    },
    newProject () {
      setOssConfig();
      this.newProjectOpen = true;
    },
    editProject (projectId) {
      setOssConfig();
      this.projectId = projectId;
      this.getProjectDetail(projectId);
    },
    getProjectDetail (id) {
      const _this = this;
      projectDetail(id).then((response) => {
        if (response.code == 200) {
          _this.newProjectOpen = true;
          _this.$nextTick(() => {
            _this.project.name = response.data.name;
            _this.project.cover = response.data.cover;
            _this.project.details = response.data.details;
            _this.project.tagList = response.data.tagList;
            _this.project.fileList = response.data.fileList;
            _this.project.enableDesign = response.data.enableDesign;
            _this.project.shareIcon = response.data.shareIcon||'';

            // 给aliOSS设置agencyId
            _this.formFilesUpload.config = {
              formId: response.data.projectId,
              agencyId: response.data.agencyId,
            };
          _this.$forceUpdate();
          });

        }
      });
    },
    // 这里是解决dialog弹出层初始化失败的问题
    onShowNewProjectDialog () {
      this.$refs.projectForm.clearValidate();
      if (!this.project.cover) {
        this.project.cover =
          this.defaultCover[
          Math.floor(Math.random() * this.defaultCover.length + 1) - 1
          ];
      }
    },
    onCloseNewProjectDialog () {
      this.newProjectOpen = false;
      this.clearProjectDialogData();
    },
    clearProjectDialogData () {
      this.$refs.projectForm.resetFields();
      this.$refs["projectForm"].clearValidate();
      this.projectId = null;
        this.project.name= ""
        this.project.cover= undefined
        this.project.details= ""
        this.project.fileList= []
        this.project.shareIcon= ''
        this.project.enableDesign= false
    },
    // 附件上传
    onHandleFileExceed () {
      this.$message.warning(`当前限制添加5个附件！`);
    },
    uploaderRef: function () {
      return this.$refs.uploader;
    },

    onBeforeFileUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.warning(`上传附件大小不能超过20MB！`);
      }
      if (isLt2M) {
        this.$emit("saveBtnClick", true);
        console.log("onBeforeFileUpload saveBtnClick true");
      } else {
        return isLt2M;
      }
    },
    onHandleFileSuccess (response, file, fileList) {
      const _this = this;
      if (!_this.isBlank(response)) {
        // delete file.response;
        // delete file.status;
      }
      _this.$emit("saveBtnClick", false);
      _this.isEdit = true;
    },
    onBeforeFileRemove (params) {
      const tmp_path = params.name;
      const index = this.designForm.fileList.findIndex(
        (item) => item.name == tmp_path
      );
      if (index != -1) {
        this.designForm.fileList.splice(index, 1);
      }
      for (let i = 0; i < this.designForm.fileList.length; i++) {
        this.designForm.fileList[i].sort = i + 1;
      }
      this.$emit("saveBtnClick", false);
      this.isEdit = true;
      // 调用删除接口
      // if(!this.isBlank(params.response)) {
      //   aliDelete(params.response.data).then((res)=>{})
      // }
    },
    // 裁剪预览图片
    onPrintImg (e) {
      const cutDownUrl = e.dataURL;
      this.cutDownUrl = cutDownUrl;
    },
    // 裁剪取消图片
    onClearAll (e) {
      this.open = false;
      this.cutDownUrl = null;
    },
    // 确认裁剪图片并上传
    onCutDownCover (e) {
      const _this = this;
      const sessionStorageUser = getSessionStorageObj("users");
      const fileName =
        sessionStorageUser.userId +
        "/" +
        Date.parse(new Date()) +
        "_" +
        e.fileName;
      putFile(fileName, e.file).then((url) => {
        _this.open = false;
        _this.project.cover = url;
        _this.msgSuccess("修改成功");
      });
    },

    // 创建应用接口请求
    httpCreateProject () {
      const param = {
        name: this.project.name,
        cover: this.project.cover,
        details: this.project.details,
        tagList: this.project.tagList,
        fileList: this.project.fileList,
        shareIcon: this.project.shareIcon,
        website: false,
        enableDesign: this.project.enableDesign,
      };
      project(param).then(async (response) => {
        if (response.code == 200) {
          this.msgSuccess("创建成功");
          this.newProjectOpen = false;
          // this.editor.destroy()
          // this.editor = null
          this.clearProjectDialogData();
          await this.getTagListByUserId();
          this.$emit("updateProjectList")
        }
      });
    },
    // 修改应用接口请求
    httpEditProject () {
      const param = {
        name: this.project.name,
        cover: this.project.cover,
        details: this.project.details,
        projectId: this.projectId,
        tagList: this.project.tagList,
        enableDesign: this.project.enableDesign,
        shareIcon: this.project.shareIcon,
        fileList: this.project.fileList.map((x) => {
          return { name: x.name, url: x.url, sort: x.sort };
        }),
        website: false,
      };
      editProject(param).then(async (response) => {
        if (response.code == 200) {
          this.msgSuccess("修改成功");
          this.newProjectOpen = false;
          // this.editor.destroy()
          // this.editor = null

          await this.getTagListByUserId();
          this.clearProjectDialogData();
          this.$emit("updateProjectList")
        }
      });
    },


    // #region 上传分享图标
    onHandleShareFileSuccess (response, file, fileList) {
      this.project.shareIcon = file.url;
    },
    clearShareIcon () {
      this.project.shareIcon = '';
      this.shareFileList.splice(0);
    },
    // #endregion
  }
}
</script>
<style lang="scss" scoped>

.project-detail-edit-dialog{
  .el-form-item {
    ::v-deep .el-form-item__content {
      text-align: left;
    }
    ::v-deep .el-upload{
      text-align: left;;
    }
  }
}

.preview-share-icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: solid 1px #efefef;
  margin-top: 10px;
  position: relative;
  &:hover {
    .el-icon-circle-close {
      display: inline-block;
    }
  }

  .el-icon-circle-close {
    display: none;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 18px;
    height: 18.5px;
    border-radius: 50%;
    font-size: 18px;
    background-color: #d95e5e;
    color: white;
    text-align: center;
    line-height: 18px;
    box-sizing: border-box;
  }
}
</style>
