var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.projectId == null ? "创建应用" : "修改应用",
        visible: _vm.newProjectOpen,
        width: "800px",
        "append-to-body": "",
        "before-close": _vm.onCloseNewProjectDialog,
        "custom-class": "project-detail-edit-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.newProjectOpen = $event
        },
        opened: _vm.onShowNewProjectDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "projectForm",
          attrs: {
            size: "small",
            model: _vm.project,
            rules: _vm.projectRules,
            "label-width": "90px",
            "label-position": "right",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "应用名称", prop: "name" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入应用名称" },
                    model: {
                      value: _vm.project.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "name", $$v)
                      },
                      expression: "project.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "封面图片", prop: "topImg" } },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    padding: "0",
                    width: "200px",
                    height: "120px",
                  },
                  attrs: { span: 8 },
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        title: "请选择一个封面",
                        width: "500",
                        trigger: "click",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "252px",
                            "overflow-y": "auto",
                          },
                        },
                        _vm._l(_vm.defaultCover, function (src, index) {
                          return _c("img", {
                            key: index,
                            staticStyle: {
                              width: "90px",
                              height: "60px",
                              padding: "2px 5px",
                              "border-radius": "8px",
                              cursor: "pointer",
                            },
                            attrs: { src: src },
                            on: {
                              click: function ($event) {
                                _vm.project.cover = src
                              },
                            },
                          })
                        }),
                        0
                      ),
                      _c("div", {
                        staticStyle: {
                          width: "100%",
                          height: "1px",
                          border: "0.5px solid #e8e8e8",
                          margin: "10px 0",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "uploadCover",
                          on: { click: _vm.editCropper },
                        },
                        [
                          _c("div", { staticClass: "uploadCover-icon" }, [
                            _c("i", { staticClass: "el-icon-upload" }),
                          ]),
                          _c("div", [
                            _c("div", { staticClass: "uploadCover-title" }, [
                              _vm._v("上传封面"),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#cecece",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v("600*360px（JPG或PNG）最大2M")]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: { width: "200px", height: "120px" },
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c("div", { staticClass: "uploader-hover" }),
                          _c("img", {
                            staticClass: "cover",
                            attrs: { src: _vm.project.cover },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标签" } },
            [
              _c("formTagEditor", {
                attrs: { readonly: false, tagList: _vm.tagList },
                model: {
                  value: _vm.project.tagList,
                  callback: function ($$v) {
                    _vm.$set(_vm.project, "tagList", $$v)
                  },
                  expression: "project.tagList",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应用介绍", prop: "details" } },
            [
              _c("richEditor", {
                attrs: {
                  formId: "",
                  agencyId: _vm.user.adminAgencyId,
                  uploadPath: "fillFile/{agencyId}/appDescription/",
                },
                model: {
                  value: _vm.project.details,
                  callback: function ($$v) {
                    _vm.$set(_vm.project, "details", $$v)
                  },
                  expression: "project.details",
                },
              }),
            ],
            1
          ),
          _vm.newProjectOpen
            ? _c(
                "el-form-item",
                { attrs: { label: "相关附件", prop: "fileList" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "inline-block" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "uploader",
                          staticClass: "upload-demo",
                          attrs: {
                            headers: { Authorization: _vm.Authorization },
                            action: "/api/ali/upload",
                            multiple: "",
                            data: _vm.uploadParam,
                            "auto-upload": true,
                            "show-file-list": false,
                            "file-list": _vm.project.fileList,
                            limit: 5,
                            "http-request": _vm.formFilesUpload.elFileUpload,
                            "before-upload": _vm.onBeforeFileUpload,
                            "on-exceed": _vm.onHandleFileExceed,
                            "on-success": _vm.onHandleFileSuccess,
                            "before-remove": function (params) {
                              return _vm.onBeforeFileRemove(params)
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "border-color": "#3476F0",
                                color: "#3476F0",
                              },
                              attrs: { icon: "el-icon-plus", size: "mini" },
                            },
                            [_vm._v("添加附件")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("fileUploadList", {
                    attrs: { showSize: false, uploader: _vm.uploaderRef },
                    model: {
                      value: _vm.fileList,
                      callback: function ($$v) {
                        _vm.fileList = $$v
                      },
                      expression: "fileList",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "启用设计" } },
            [
              _c("el-switch", {
                attrs: { "active-color": "#13ce66" },
                model: {
                  value: _vm.project.enableDesign,
                  callback: function ($$v) {
                    _vm.$set(_vm.project, "enableDesign", $$v)
                  },
                  expression: "project.enableDesign",
                },
              }),
            ],
            1
          ),
          _vm.project.enableDesign
            ? _c("designView", {
                ref: "designView",
                attrs: { type: "1", businessId: _vm.projectId },
              })
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "分享图标" } },
            [
              !_vm.project.shareIcon
                ? _c(
                    "el-upload",
                    {
                      ref: "shareUploader",
                      staticStyle: { "text-align": "left" },
                      attrs: {
                        headers: { Authorization: _vm.Authorization },
                        action: "/oss/endpoint/put-file",
                        multiple: "",
                        data: _vm.uploadParam,
                        "auto-upload": true,
                        "show-file-list": false,
                        "file-list": _vm.shareFileList,
                        limit: 1,
                        "http-request": _vm.shareFilesUpload.elFileUpload,
                        "on-success": _vm.onHandleShareFileSuccess,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "border-color": "#3476f0",
                            color: "#3476f0",
                          },
                          attrs: { icon: "el-icon-plus", size: "mini" },
                        },
                        [_vm._v("上传")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.project.shareIcon
                ? _c(
                    "div",
                    {
                      staticClass: "preview-share-icon",
                      style: {
                        backgroundImage: "url('" + _vm.project.shareIcon + "')",
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "el-icon-circle-close",
                        on: {
                          click: function ($event) {
                            return _vm.clearShareIcon()
                          },
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.onSubmitNewProjectClick },
            },
            [_vm._v("确 定")]
          ),
          _c("el-button", { on: { click: _vm.onCloseNewProjectDialog } }, [
            _vm._v("取 消"),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改应用封面图片",
            visible: _vm.open,
            width: "1000px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c("ImgCutter", {
            ref: "imgCutterModal",
            attrs: {
              isModal: false,
              boxWidth: 500,
              boxHeight: 400,
              cutWidth: 300,
              cutHeight: 204,
              sizeChange: true,
              moveAble: true,
              tool: false,
              previewMode: true,
              crossOrigin: true,
              crossOriginHeader: "*",
              scaleAble: false,
              imgMove: false,
              rate: "5:3",
            },
            on: {
              cutDown: _vm.onCutDownCover,
              onPrintImg: _vm.onPrintImg,
              onClearAll: _vm.onClearAll,
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                right: "20px",
                top: "85px",
                border: "1px solid #eee",
                width: "450px",
                height: "400px",
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
              },
            },
            [
              _c("img", {
                staticStyle: {
                  display: "inline-block",
                  width: "auto",
                  height: "auto",
                  float: "right",
                },
                attrs: { src: _vm.cutDownUrl },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }